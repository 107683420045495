import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    BaseQueryApi,
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { RequestQueue } from './request-queue';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import { resetAuth } from '~/store/slice/auth/auth-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

const isProduction = process.env.NODE_ENV === 'production';

const requestQueue = new RequestQueue();

const baseQuery = fetchBaseQuery({
    credentials: 'include',
});

const queueRequest = (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: object,
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, object>> =>
    new Promise((res, rej) => {
        requestQueue.subscribe(async () => {
            const response = await baseQuery(args, api, extraOptions);

            if (response.error) {
                rej(response.error);
            } else {
                res(response);
            }
        });
    });

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    if (requestQueue.shouldSubscribe) {
        return queueRequest(args, api, extraOptions);
    }

    const sessionId = getLocalStorageItem(LOCAL_STORAGE.sessionId);
    const response = await baseQuery(args, api, extraOptions);

    // логика протухания токена
    if (response.error && response.error.status === 401 && sessionId) {
        // дополнительная проверка если два запроса одновременно pending, и один падает раньше
        if (requestQueue.shouldSubscribe) {
            return queueRequest(args, api, extraOptions);
        }

        requestQueue.shouldSubscribe = true;

        const refreshResponse = await baseQuery(
            {
                url: API.refreshToken,
                method: 'POST',
                body: {
                    sessionId: Number(sessionId),
                },
            },
            api,
            extraOptions,
        );

        if (refreshResponse.error) {
            api.dispatch(resetAuth());
            requestQueue.reset();

            return response;
        }

        requestQueue.notify();

        return baseQuery(args, api, extraOptions);
    }

    return response;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    refetchOnFocus: false,
    refetchOnReconnect: isProduction,
});
