export const STEP_PARAMS = 'stepName';

export const ROUTES = {
    startPage: '/',
    stepsRootPage: '/steps',
    stepPage: `:${STEP_PARAMS}`,
    esiaPage: '/esia',

    privateRoom: '/private-room',

    certificateTable: '/certificate-table',
    archive: '/archive',
    mchd: '/mchd',

    certificateTemplate: '/certificate-template',
    applicationForm: '/application-form',

    validation: '/validation',
    checkCertificate: '/check-certificate',
    checkDocument: '/check-document',
    signDocument: '/sign-document',

    notFound: '/not-found',
};

export const enum ApplicationFormQuery {
    folderId = 'folder_id',
    templateId = 'template_id',
    draftId = 'draft_id',
    kpp = 'kpp',
    inn = 'inn',
}
