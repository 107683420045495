import { Middleware } from '@reduxjs/toolkit';

import { resetAuth } from '../slice/auth/auth-slice';

export const logoutMiddleware: Middleware = () => (next) => (action) => {
    if (resetAuth.match(action)) {
        localStorage.clear();
    }

    return next(action);
};
