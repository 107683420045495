import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Space, Spin, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import classNames from 'classnames';

import styles from './application-tab.module.css';

import { ROUTES } from '~/app-router/routes';
import { ApplicationFormSection } from '~/components/application-tab/application-form-section/application-form-section';
import { TOOLTIP_TEXT_FOR_FORM } from '~/components/certificates-table/constants/general';
import { NotificationGosUslugi } from '~/constants/application-tab/general';
import { BUTTON_TEXT } from '~/constants/button';
import { useAppDispatch } from '~/hooks';
import { useCreateApplication } from '~/hooks/application-tab/use-create-application';
import { useFormSearchQuery } from '~/hooks/application-tab/use-form-search-query';
import { useInitialForm } from '~/hooks/application-tab/use-initial-form';
import { useUpdateDraft } from '~/hooks/application-tab/use-update-draft';
import { useGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { CustomButton } from '~/shared/custom-button';
import { FormTooltip } from '~/shared/form-components/form-tooltip';
import { setNavigationDisabled } from '~/store/slice/app-tab/app-tab-slice';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { getDraftButtonTooltip } from '~/utils/application-tab/get-draft-button-tooltip';

export const ApplicationTab = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [form] = useForm<CertificateForm>();

    const { folderId, templateId } = useFormSearchQuery();
    const { data: templatesData, isFetching: isFetchingTemplate } = useGetTemplateQuery();

    const template = useMemo(
        () =>
            templatesData?.templates.find(
                ({ id, folderId: templateFolderId }) =>
                    templateId === id && folderId === templateFolderId,
            ),
        [templatesData, templateId, folderId],
    );

    const { formData, isInitialLoading, enabledAttributeMap } = useInitialForm(form);

    const { updateDraft, isLoadingDraftCertificate } = useUpdateDraft(
        form,
        enabledAttributeMap,
        template?.displayName,
    );

    const { createApplication, isLoadingCreateCertificate } = useCreateApplication(
        form,
        enabledAttributeMap,
        template?.displayName,
    );

    const isLoading =
        isFetchingTemplate ||
        isInitialLoading ||
        isLoadingCreateCertificate ||
        isLoadingDraftCertificate;

    const isFormPending = isLoadingCreateCertificate || isLoadingDraftCertificate;

    const navigateToTemplate = () => {
        navigate(`${ROUTES.privateRoom}${ROUTES.certificateTemplate}`);
    };

    useEffect(() => {
        dispatch(setNavigationDisabled(true));
    }, [dispatch]);

    return (
        <Spin spinning={isLoading} className={styles.spinner}>
            {!isFetchingTemplate && (
                <Flex className={styles.formHeader} vertical={true} gap={20}>
                    <div className={styles.titleBlock}>
                        <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topRight'>
                            <Button
                                type='text'
                                className={styles.backButton}
                                onClick={navigateToTemplate}
                            >
                                <ArrowLeftOutlined className={styles.topArrowIcon} />
                            </Button>
                        </FormTooltip>

                        <Typography.Title className={styles.applicationTitle} level={3}>
                            {template?.displayName ?? ''}
                        </Typography.Title>
                    </div>

                    <Typography.Title className={styles.changeDataNotification} level={4}>
                        <Typography.Text>{NotificationGosUslugi.start}</Typography.Text>

                        <RouterLink to={NotificationGosUslugi.link} target='_blank'>
                            {NotificationGosUslugi.linkText}
                        </RouterLink>

                        <Typography.Text>{NotificationGosUslugi.end}</Typography.Text>
                    </Typography.Title>
                </Flex>
            )}

            <Form
                form={form}
                className={classNames(styles.applicationTabForm, {
                    [styles.hidden]: isInitialLoading,
                })}
                layout='vertical'
            >
                {formData && (
                    <div className={styles.applicationTabBlocks}>
                        {formData.blocks.map((block) => (
                            <ApplicationFormSection
                                {...{ block, dictionaries: formData.dictionaries }}
                                key={block.displayName}
                            />
                        ))}

                        <Flex
                            wrap={true}
                            vertical={true}
                            align='flex-start'
                            className={styles.buttonsRow}
                            gap={32}
                        >
                            <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topLeft'>
                                <Link onClick={navigateToTemplate}>
                                    <ArrowLeftOutlined className={styles.arrowIcon} />
                                    {BUTTON_TEXT.back}
                                </Link>
                            </FormTooltip>
                            <Space size={32}>
                                <Tooltip
                                    title={getDraftButtonTooltip()}
                                    placement='top'
                                    styles={{ root: { maxWidth: 'var(--button-width-desktop)' } }}
                                >
                                    <div>
                                        <CustomButton
                                            disabled={isFormPending}
                                            className='updatePageButton'
                                            onClick={updateDraft}
                                        >
                                            {BUTTON_TEXT.saveDraft}
                                        </CustomButton>
                                    </div>
                                </Tooltip>

                                <CustomButton
                                    disabled={isFormPending}
                                    className='applicationTabButton'
                                    onClick={createApplication}
                                    type='primary'
                                >
                                    {BUTTON_TEXT.sendRequest}
                                </CustomButton>
                            </Space>
                        </Flex>
                    </div>
                )}
            </Form>
        </Spin>
    );
};
