import { useCallback, useEffect, useState } from 'react';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ALTER_AUTH_DESCRIPTION, AuthModalError, ModalTextVariant } from '~/constants/modal';
import {
    useAuthByCertMutation,
    useEnsureSessionMutation,
} from '~/rtk-queries/endpoints/authentication';
import { CommonResponse } from '~/types/common';
import { signAuthRequest } from '~/utils/plugin/sign-auth-request';

export const useCertificateAuthorization = () => {
    const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [answerForModal, setAnswerForModal] = useState({ text: '', title: '', isError: false });
    const [isDisabledCards, setIsDisabledCards] = useState(false);

    const [authByCert, { isError: isErrorInAuthByCert, isLoading: isLoadingInAuthByCert, error }] =
        useAuthByCertMutation();

    const [ensureSession] = useEnsureSessionMutation();

    const authorizationByCertificateHandler = useCallback(
        async (thumbprint: string) => {
            try {
                setIsDisabledCards(true);

                const sessionData = await ensureSession().unwrap();
                const signedContent = await signAuthRequest(
                    sessionData?.base64KeyString || '',
                    thumbprint,
                );

                if (signedContent.isError) {
                    setIsDisabledCards(false);
                    setAnswerForModal({
                        text: signedContent.text,
                        title: signedContent.title,
                        isError: true,
                    });
                    setIsOpenAnswerModal(true);
                } else {
                    authByCert({
                        sessionId: sessionData?.sessionId,
                        signedContent: signedContent.text,
                        thumbprint,
                    });
                    localStorage.setItem(LOCAL_STORAGE.thumbprint, thumbprint);
                    setIsDisabledCards(false);
                }
            } catch (err: unknown) {
                console.error(err);
            }
        },
        [authByCert, ensureSession],
    );

    useEffect(() => {
        if (isErrorInAuthByCert && error) {
            const message = (error as CommonResponse).message?.toLocaleLowerCase();

            type ErrorInfo = {
                title: string;
                text: string;
            };

            let errorInfo: ErrorInfo = {
                title: ModalTextVariant.error,
                text: ModalTextVariant.tryAgain,
            };

            AuthModalError.forEach(({ errorPattern, title }) => {
                if (message?.includes(errorPattern)) {
                    errorInfo = { title, text: ALTER_AUTH_DESCRIPTION };
                }
            });

            setAnswerForModal({
                title: errorInfo.title,
                text: errorInfo.text,
                isError: true,
            });
            setIsOpenAnswerModal(true);
        }
    }, [error, isErrorInAuthByCert]);

    return {
        authorizationByCertificateHandler,
        isDisabledCards,
        isLoadingInAuthByCert,
        isErrorInAuthByCert,
        isOpenAnswerModal,
        setIsOpenAnswerModal,
        answerForModal,
        setAnswerForModal,
    };
};
