import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/query';
import { Form, Select, Tooltip, Typography } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';

import styles from './certificate-template.module.css';

import { ApplicationFormQuery, ROUTES } from '~/app-router/routes';
import { BUTTON_TEXT } from '~/constants/button';
import { ORGANIZATION_CAVEAT_TOOLTIP } from '~/constants/certificate-template/general';
import { CertificateTemplateValidation as Validation } from '~/constants/certificate-template/validation';
import { useCheckTemplateTypeQuery, useGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { CustomButton } from '~/shared/custom-button';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { PickTemplateForm } from '~/types/certificate-template/certificate-template-form';
import { OptionType } from '~/types/common';

export const CertificateTemplate = () => {
    const navigate = useNavigate();
    const [form] = useForm<PickTemplateForm>();
    const { data: templatesData, isLoading: isTemplatesLoading } = useGetTemplateQuery();

    const selectedTemplateId = useWatch('templateId', form);

    const selectedTemplate = useMemo(
        () =>
            templatesData?.templates.find(
                ({ id, folderId }) => selectedTemplateId === `${folderId}-${id}`,
            ),
        [selectedTemplateId, templatesData],
    );

    const { data: templateType, isFetching: isTemplateTypeChecking } = useCheckTemplateTypeQuery(
        selectedTemplate?.folderId ?? skipToken,
    );

    const isLoading = isTemplatesLoading || isTemplateTypeChecking;

    const templateOptions = useMemo<OptionType[]>(() => {
        if (!templatesData) {
            return [];
        }

        return templatesData.templates.map(({ id, folderId, displayName }) => ({
            value: `${folderId}-${id}`,
            label: displayName,
        }));
    }, [templatesData]);

    const navigateToForm = async ({ kpp, inn }: PickTemplateForm) => {
        if (selectedTemplate) {
            const { id, folderId } = selectedTemplate;

            const searchQuery = new URLSearchParams({
                [ApplicationFormQuery.folderId]: String(folderId),
                [ApplicationFormQuery.templateId]: String(id),
            });

            if (kpp && inn) {
                searchQuery.set(ApplicationFormQuery.kpp, kpp);
                searchQuery.set(ApplicationFormQuery.inn, inn);
            }

            navigate(
                `${ROUTES.privateRoom}${ROUTES.certificateTemplate}${
                    ROUTES.applicationForm
                }?${searchQuery.toString()}`,
            );
        }
    };

    return (
        <Form
            layout='vertical'
            form={form}
            disabled={isLoading}
            className={styles.templateForm}
            onFinish={navigateToForm}
        >
            <Form.Item
                name={Validation.templateId.item.name}
                label={Validation.templateId.item.label}
                className={styles.templateItem}
                extra={selectedTemplate?.description}
            >
                <Select
                    loading={isLoading}
                    className={styles.templateSelect}
                    options={templateOptions}
                    placeholder={Validation.templateId.input.placeholder}
                />
            </Form.Item>

            {!!templateType?.ul && (
                <div>
                    <Typography.Title className={styles.organizationBlockTitle} level={5}>
                        Данные организации
                        <Tooltip
                            placement='rightTop'
                            arrow={{ pointAtCenter: true }}
                            title={ORGANIZATION_CAVEAT_TOOLTIP}
                        >
                            <QuestionCircleOutlined className={styles.caveatIcon} />
                        </Tooltip>
                    </Typography.Title>

                    <div className={styles.organizationBlock}>
                        <CustomMaskInput
                            formItemProps={Validation.inn.item}
                            inputProps={{
                                disabled: isLoading,
                                ...Validation.inn.input,
                            }}
                        />
                        <CustomMaskInput
                            formItemProps={Validation.kpp.item}
                            inputProps={{
                                disabled: isLoading,
                                ...Validation.kpp.input,
                            }}
                        />
                    </div>
                </div>
            )}

            <CustomButton
                className='applicationTabButton'
                disabled={!selectedTemplate && !isLoading}
                htmlType='submit'
                loading={isLoading}
            >
                {BUTTON_TEXT.continue}
            </CustomButton>
        </Form>
    );
};
