import { PluginError } from './plugin';

import { ErrorText } from '~/types/errors';

export enum ModalType {
    revoke = 'revoke',
    viewAnswer = 'viewAnswer',
    generateKeys = 'generateKeys',
    print = 'print',
}

export enum AuthErrorMessage {
    revoked = 'revoked',
    notPKI = "didn't find",
    snils = 'snils mustn',
    notValid = "isn't valid",
}

export enum ModalTextVariant {
    generateKeys = 'Для генерации ключевой пары подключите съемный носитель (USB-Token). На\u00A0нём будет создан контейнер с\u00A0электронными закрытыми ключами, необходимым для\u00A0установки сертификата. Сертификат можно будет установить во\u00A0время посещения Банка (возьмите USB-носитель с\u00A0собой), либо сделать это\u00A0самостоятельно в\u00A0личном кабинете, после посещения\u00A0Банка.',
    installOk = 'Сертификат успешно установлен.',
    isNotInPersonalStorage = 'Сертификат не установлен в личное хранилище.',
    keyUndefined = 'Контейнер с ключами не обнаружен.',
    keyOrContainerUndefined = 'Контейнер не обнаружен либо сертификат не установлен в контейнер.',
    incorrectParameter = 'Параметр задан неверно.',
    addContainer = 'Вставьте носитель с контейнером.',
    createRequest = 'Новый сертификат',
    createDraft = 'Черновик',
    tryAgain = 'Попробуйте еще раз.',
    error = 'Ошибка',
    cancel = 'Действие было отменено пользователем',
    dataReset = 'Данные будут сброшены. Вы уверены, что хотите перейти?',
    needExtension = 'Включите расширение браузера CryptoPro Extension for CAdES Browser Plug-in и перезагрузите страницу.',
    needPlugin = 'Расширение установлено и активировано. Для работы со средством электронной подписи установите плагин.',
    needCSP = 'Расширение и плагин установлены и активированы. Для работы со средством электронной подписи установите КриптоПро CSP.',
    needInstall = ' Для установки перейдите по',
    linkForInstall = ' ссылке.',
    protectedMedia = 'Внимание! Тип носителя не подходит для генерации ключевой пары. Подключите USB-Token к ПК и повторите данную операцию. Список доступных считывателей:',
    answer = 'Результаты проверок',
    removeDraft = 'Черновик был успешно удалён.',
    errorTemplateMessage = 'Удостоверяющий центр больше не выпускает сертификат с таким назначением. Пожалуйста, удалите черновик и создайте новую заявку на сертификат.',
    attention = 'Внимание!',
    needExtensionTitle = 'Отсутствует расширение браузера',
    needPluginTitle = 'Отсутствует КриптоПро ЭЦП Browser plug-in',
    needCSPTitle = 'Отсутствует КриптоПро CSP',
    noLicense = 'Отсутствует лицензия для КриптоПро CSP',
    notFound = 'Не удается найти сертификат и закрытый ключ для расшифровки. Авторизуйтесь по другому сертификату или войдите через Госуслуги.',
    accessDeniedAuth = 'Вход по данному сертификату невозможен. Выберите другой сертификат или авторизуйтесь с помощью Госуслуг.',
    accessDeniedSign = 'Подписание данным сертификатом невозможно. Выберите другой сертификат для подписи.',
    printError = 'Операция  в данный момент недоступна. Попробуйте позже.',
}

export const ALTER_AUTH_DESCRIPTION =
    'Выберите другой сертификат либо авторизуйтесь через Госуслуги.';

export const AuthModalError = [
    {
        errorPattern: AuthErrorMessage.revoked,
        title: 'Сертификат отозван',
    },

    {
        errorPattern: AuthErrorMessage.notPKI,
        title: 'Вход по данному сертификату не возможен',
    },

    {
        errorPattern: AuthErrorMessage.snils,
        title: 'В сертификате отсутствует СНИЛС',
    },

    {
        errorPattern: AuthErrorMessage.notValid,
        title: 'Сертификат не прошел проверку',
    },
];

export const ERROR_TEXTS: ErrorText = {
    [PluginError.cancel]: ModalTextVariant.cancel,
    [PluginError.cancelInInstall]: ModalTextVariant.cancel,
    [PluginError.isNotInPersonalStorage]: ModalTextVariant.isNotInPersonalStorage,
    [PluginError.keyUndefined]: ModalTextVariant.keyUndefined,
    [PluginError.incorrectParameter]: ModalTextVariant.incorrectParameter,
    [PluginError.lostConnection]: ModalTextVariant.needExtension,
    [PluginError.containerNotFound]: ModalTextVariant.keyUndefined,
    [PluginError.internalError]: ModalTextVariant.keyUndefined,
    [PluginError.noLicense]: ModalTextVariant.noLicense,
    [PluginError.notFound]: ModalTextVariant.notFound,
    [PluginError.accessDenied]: ModalTextVariant.accessDeniedAuth,
    default: ModalTextVariant.addContainer,
};
