import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '~/app-router/routes';
import { useFormSearchQuery } from '~/hooks/application-tab/use-form-search-query';

export const ApplicationFormGuard: FC<PropsWithChildren> = ({ children }) => {
    const { folderId, templateId } = useFormSearchQuery();

    if (!folderId || !templateId) {
        return (
            <Navigate to={`${ROUTES.privateRoom}${ROUTES.certificateTemplate}`} replace={true} />
        );
    }

    return children;
};
