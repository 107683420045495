import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApplicationFormQuery, ROUTES } from '~/app-router/routes';
import { ModalTextVariant, ModalType } from '~/constants/modal';
import { useAppDispatch } from '~/hooks';
import { useRemoveDraftMutation } from '~/rtk-queries/endpoints/draft';
import { openModal } from '~/store/slice/modal/modal-slice';
import { isCertificatePreApplication } from '~/utils/is-certificate-variant';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const useDraftActions = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [
        removeDraft,
        {
            isSuccess: isSuccessRemoveDraft,
            isLoading: isLoadingLoadingRemoveDraft,
            reset: resetRemoveDraft,
        },
    ] = useRemoveDraftMutation();

    const continueDraft = useCallback(
        async (data: Certificate) => {
            if (isCertificatePreApplication(data)) {
                const { folderId, templateId, lkRequestId } = data;

                const searchQuery = new URLSearchParams({
                    [ApplicationFormQuery.folderId]: String(folderId),
                    [ApplicationFormQuery.templateId]: String(templateId),
                    [ApplicationFormQuery.draftId]: String(lkRequestId),
                });

                navigate(
                    `${ROUTES.privateRoom}${ROUTES.certificateTemplate}${
                        ROUTES.applicationForm
                    }?${searchQuery.toString()}`,
                );
            }
        },
        [navigate],
    );

    const deleteDraft = useCallback(
        async (data: Certificate) => {
            if (isCertificatePreApplication(data)) {
                const RequestId = data.lkRequestId;
                const { data: removeData } = await removeDraft({ RequestId });

                if (removeData) {
                    dispatch(
                        openModal({
                            type: ModalType.viewAnswer,
                            text: removeData.hasError
                                ? ModalTextVariant.tryAgain
                                : ModalTextVariant.removeDraft,
                            title: removeData.hasError ? ModalTextVariant.error : '',
                            isError: removeData.hasError || false,
                        }),
                    );
                }
            }
        },
        [dispatch, removeDraft],
    );

    return {
        continueDraft,
        deleteDraft,
        isSuccessRemoveDraft,
        isLoadingLoadingRemoveDraft,
        resetRemoveDraft,
    };
};
